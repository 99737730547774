// 营销中心
<template>
    <div class="marketing_center">
        营销中心
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.marketing_center {
}
</style>